import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import eezibLogo from "../images/eezib2.png"
import { Box, Grow, Paper, Zoom } from '@mui/material';
import B2cCardForm from './B2cCardForm';
import { HandleApi } from '../HandleApi';
import Cookies from "js-cookie";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({open, data, clientData, onClose }) {

    const [ openform , setOpenform ] = React.useState(false);
    const [formData , setFormData ] = React.useState();
    const [ userData , setUserData ] = React.useState();
    const [jsonError , setJsonError] = React.useState('');
    const [activateOpen , setActiveOpen] = React.useState(false);
    const [ closeForm , setCloseForm ] = React.useState(false);



    async function handleOpenForm(row){
        setOpenform(true);
        setFormData(row)
    }

    console.log("open form : ",openform);

    console.log("Prop data : ",data);

    const handleRedirection = async () => {
  
      const url = process.env.REACT_APP_UAPI_URL;
      const apiUrl = `${url}/api/generate_token`;
      const method = "GET";
      const authAccess = Cookies.get("demoCAuth");

  
      const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess });
  
      if(json){
        if(json.status === "success"){
  
          // window.alert(json.data)
  
          Cookies.remove("demoCAuth");
          Cookies.set("demoCAuth",json.data);
  
          if(process.env.NODE_ENV === "production"){
            window.open(`${process.env.REACT_APP_UAPI_URL}/eezib?home=${json.data}`, ""); 
          }else{
            window.open(`http://192.168.17.15:3001/eezib?home=${json.data}`,"");
          }
  
  
          // window.alert(json.data)
          window.location.reload();
        }
        if(json.status === "error"){
          setJsonError(json.message);
          setActiveOpen(true);
        }
      }
    }


  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        sx={{ minHeight:"100vh" }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', width:"100%" }} >
            <Box component="img" onClick={() => window.open(`${process.env.REACT_APP_UAT_URL}`) } src={eezibLogo} sx={{ width:"8rem" }} />
            <Button onClick={() => handleRedirection()}  autoFocus color="inherit" sx={{ fontFamily:"Poppins", fontWeight:400 }} >
              Voucher Mania
            </Button>
            </Box>
          </Toolbar>
        </AppBar>

        <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', mt:"2rem", margin:'' }} >
            <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:{lg:"1.5rem", md:"1.3rem", sm:"1rem", xs:"0.8rem" }, color:'#022866' }} >Empower Your Wallet With Range of eezib Cards</Typography>
        </Box>

          <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', mt:"5rem" }} >
              <Box sx={{ display:'grid', gridTemplateColumns:{ xl:'repeat(3,1fr)', lg:'repeat(3,1fr)', md:'repeat(2,1fr)', sm:'repeat(2,1fr)', xs:"repeat(1,1fr)" }, gridColumnGap:"5rem", gridRowGap:"5rem",  }} >
          {
            Array.isArray(data) && data.map((row, index) => (
              <Paper elevation={24} sx={{ padding:"0rem 1.5rem 1rem 1.5rem", borderRadius:'1rem', cursor:"default" }} >
                      <Box component="img" onClick={()=>handleOpenForm(row)} src={row.card_url} sx={{ width:{lg:"18rem", md:"18rem", sm:"15rem", xs:"15rem" }, marginTop:'-2rem', borderRadius:2, cursor:'pointer' }} />
                      <Typography sx={{ fontFamily:"poppins", fontWeight:400, fontSize:{lg:'1rem', md:"1rem", sm:"0.9rem", xs:"0.8rem" } }}>{row.category_name}</Typography>
                      <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:{lg:'0.8rem', md:"0.8rem", sm:"0.7rem", xs:"0.65rem" }, color:"#636363" }} >{row.sub_category_name}</Typography>
                  </Paper>
              ))
            }

              </Box>
          </Box>
      </Dialog>


      <B2cCardForm openData={openform} data={formData} clientData={clientData}  closeDialogue={() => setOpenform(false)} onClose={() => onClose()} />
    </React.Fragment>
  );
}
