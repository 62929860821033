import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box, Checkbox, CircularProgress, Divider, FormControl, LinearProgress, Menu, MenuItem, Radio, Select, TextField, Tooltip, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import ProfileSection from './ProfileSection';
import eezibWhite from "../images/eezib2.png"
import Cookies from "js-cookie";
import { HandleApi } from '../HandleApi';
import SnackBarSuccess from './SnackBarSuccess';
import CloseIcon from '@mui/icons-material/Close';
import eezibLogo from "../images/eezib.png";
import pgImage from "../images/pgIMage.png";
import eeLogo from "../images/ee.png";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import userQr from "../images/qrCode.png"
import cardIcon from "../images/pgIcon.png";
import env from 'react-dotenv';
import confetti from 'canvas-confetti';
import orderImage from "../images/orderCompleted.jpg";
import { ConnectingAirportsOutlined } from '@mui/icons-material';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function B2cCardForm({ openData, data , clientData, closeDialogue, setOpenform , onClose }) {



  console.log("FORM DATA : ",clientData);

  const [openProfile, setOpenProfile] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [ communiAddress , setCommuniAddress ] = React.useState(false);
  const [check, setCheck] = React.useState(false);
  const  [ selectedValue , setSelectedValue ] = React.useState("");
  const [ progress , setProgress ] = React.useState(false);
  const [ progress2 , setProgress2 ] = React.useState(false);
  const [ successDialogue , setSuccessDialogue ] = React.useState(false);
  const [ openQr, setOpenQr ] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false); 
  const [open, setOpen] = React.useState(false);
  const [ snackRightEOpen, setSnackRightEOpen ] = React.useState(false);
  const [snackEError , setSnackEError] = React.useState('');
  const [ snackPendOpen , setSnackPendOpen ] = React.useState(false);
  const [ snackPendMessage , setSnackPendMessage ] = React.useState("");
  const [denomination, setSelectedMenu] = React.useState("");
  const [ pgUrl , setPgUrl ] = React.useState('');
  const [ openUrlDialogue , setOpenUrlDialogue ] = React.useState(false);
  const [ handleError, setHandleError ] = React.useState(false);
  const [ qrCode , setQrCode ] = React.useState('');
  const [ qrName , setQrName ] = React.useState("");
  const [seconds, setSeconds] = React.useState(90); 
  const [progress3, setProgress3] = React.useState(false);

  const [ userPan , setUserPan ] = React.useState("");


  const [ openSnack , setOpenSnack ] = React.useState("");
  const [ snackStatus , setSnackStatus ] = React.useState("");
  const [ snackMessage , setSnackMessage ] = React.useState(""); 
  const [ lowbalance , setLowBalance ] = React.useState(false);
  const [ lowBalanceData , setLowbalanceData ] = React.useState();
  const [ proceedCoin , setProceedCoin ] = React.useState(0);
  const  [ handleSuccess , setHandleSuccess ] = React.useState(false);
  const [ jsonSuccess, setJsonSuccess ] = React.useState("");
  const [ jsonError , setJsonError ] = React.useState("");


  const [anchorEl, setAnchorEl] = React.useState(null);
  const openOrder = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const name = Cookies.get("userName");
  const authAccess = Cookies.get("demoCAuth");


  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const userData = clientData && clientData.length > 0 ? clientData[0] : {}
  // const userData = clientData[0];

  const [formData , setFormData] = React.useState({
    name:"",
    email:"",
    phone:"",
    dob:"",
    fatherName:"",
    gender:"",
    pan:"",
    commAddress:"",
    commAddress2:"",
    commAddress3:"",
    commCity:"",
    commState:"",
    commPincode:"",
    permAddress1:"",
    permAddress2:"",
    permAddress3:"",
    permCity:"",
    permState:"",
    permPincode:"",
    amount:"",
  });

  const [formAmount , setFormAmount] = React.useState();


  React.useEffect(() => {
    let interval = null;

    if (isActive && seconds > 0) {
      setIsActive(true);
      setOpenQr(true);
        interval = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);
    } else if (seconds === 0) {
        // Stop everything when the timer reaches zero
        setIsActive(false);
        setOpenQr(false);
        // setLowBalance(false);
        // }, [openQr, isActive , selectedValue, proceedCoin ]);
          setProceedCoin(0);
          setSelectedValue('');
          setSeconds(90);
    }

    // Cleanup the interval when the component unmounts or when the timer ends
    return () => clearInterval(interval);
}, [isActive, seconds]);


// Optional: Ensure API is not called if the timer ends during an API call
React.useEffect(() => {
    if (seconds === 0) {
        // Cleanup any remaining intervals for the API call
        setIsActive(false);
        setOpenQr(false);
        setProceedCoin(0);
        setSelectedValue('');
        setSeconds(90);
    }
}, [seconds]);
  
  const handleSDialogue = () => {

    const end = Date.now() + 2 * 1000; // 15 seconds
    const colors = ["#57bcff", "#ffffff"];
  
    // Define the continuous confetti animation
    (function frame() {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors,
        zIndex: 9999,
      });
  
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors,
        zIndex: 9999,
      });
  
      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();

    setTimeout(() => {
        setSuccessDialogue(true);
    },0)
    }

    const handleSuccessClose = () => {
      setSuccessDialogue(false)
    }

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      // console.log("handle input name:", name);
    
      if (name === "fatherName") {
        const regex = /^[A-Za-z\s]+$/;
    
        // Check if the value passes the regex and is within the character limit
        if ((regex.test(value) || value === "") && value.length <= 20) {
          setFormData((prevData) => ({
            ...prevData,
            [name]: value, // Dynamically set the value based on the input name
          }));
        } else if (value.length > 20) {
          // console.log("Invalid input for fatherName: Maximum length is 20 characters.");
        } else {
          // console.log("Invalid input for fatherName: Only alphabets are allowed.");
        }
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value, // Handle other inputs without validation
        }));
      }
    };
    
    
  

  const handleProfileClose = () => {
    setOpenProfile(false);
  }

  const handleLowBalance = () => {
    setLowBalance(false);
  }

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    };

    const handleExceptErr =  async() => {
      setOpenSnack(true);
      setSnackMessage("Please choose valid payment option.");
      setSnackStatus("warning");
  }
  
  const handleCheck = (event) => {
    const check = event.target.checked;
    if(check){
     setProceedCoin(1);
     setCheck(true)
    }else{
        setProceedCoin(0);
        setCheck(false)
    }
  }


  const handleFormSubmit = async(e) => {
    e.preventDefault();

    if( data?.card_charges === 0 && formAmount < 100){
      setOpenSnack(true);
      setSnackMessage("Card Balance Should Be Greater Then 100");
      setSnackStatus("warning");
      return;
 }

    // console.log("client data : ",clientData);
    // console.log("card data :",data);


  const ApiBody =   {
      "productName": data?.sub_category_name,
      "category_id": data?.category_id,
      "subcategory_id": data?.sub_category_id,
      "bin": data?.bin,
      "name": clientData?.name,
      "email": clientData?.email,
      "phone_no": clientData?.phone_no,
      "gender": formData?.gender,
      "amount": data?.card_charges === 0 ? formAmount : data?.card_charges ,
      "dob": clientData?.dob,
      "pan":clientData?.pan_no === null || clientData?.pan_no === "" ? formData?.pan : clientData?.pan_no,
      "father_name": formData?.fatherName,
      "addressinfo": [
          {
              "addresscategory": "COMMUNICATION",
              "address1": clientData?.address1 ,
              "address2": clientData?.address2 ? clientData?.address2 : "" ,
              "address3": clientData?.address3 ? clientData?.address3 : "" ,
              "city": clientData?.city,
              "state": clientData?.state ,
              "pincode": clientData?.pincode
          },
          {
              "addresscategory": "PERMANENT",
              "address1": checked? clientData?.address1 : formData?.permAddress1  ,
              "address2": checked? clientData?.address2 ? clientData?.address2 : formData?.permAddress2 : "" ,
              "address3": checked? clientData?.address3 ? clientData?.address3 : formData?.permAddress3 : "" ,
              "city": checked? clientData?.city : formData?.permCity ,
              "state": checked? clientData?.state : formData?.permState ,
              "pincode": checked? clientData?.pincode : formData?.permPincode 
          }
      ]
  }
  // console.log("form data : ",ApiBody);

    const url = process.env.REACT_APP_UAPI_URL;
    const apiUrl = `${url}/api/cardInitiate`;
    const method = "POST";
    const authAccess = Cookies.get("demoCAuth");
  
    const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess, bodyData:ApiBody });

    //     <SnackBarSuccess open={openSnack} status={snackStatus} message={snackMessage} snackClose={() => setOpenSnack(false) } />

    if(json){
        if(json.status === "success"){
            setOpenSnack(true);
            setSnackStatus("success");
            setSnackMessage(json.message);  
            setLowBalance(true);
            setLowbalanceData(json.data)      
            closeDialogue();    
        }
        else if(json.status === "error" && json.statuscode !== 902 ){
          setOpenSnack(true);
            setSnackStatus("error");
            setSnackMessage(json.message);  
            setSnackMessage(json.message);  
            setLowBalance(true);
            setLowbalanceData(json.data); 
            closeDialogue();
        }
        else if(json.status === "error" && json.statuscode === 902 ){
            setSnackStatus("error");
            setSnackMessage(json.message);  
            setOpenSnack(true);
        }


    }
}

  const handleAmount = (amount) => {
    let value = parseInt(amount);
  
    if (isNaN(value)) {
      value = ""; // Reset to empty if input is invalid
    } else if (value > 10000) {
      // value = 10000; // Cap at 10000
      return;
    }
  
    setFormAmount(value); // Update state with the corrected value
  };

  function handleEdit(){
    closeDialogue();
    setOpenProfile(true)
    // setOpenform(false)
    onClose()
  }



  const handleWalletRequest =  async() => {

    if(lowBalanceData.topup_amount !== 0 && selectedValue === "" ){
      console.log("topup amount : ",lowBalanceData.topup_amount);
      console.log("selected value : ", selectedValue);
      console.log("condition failed");
      setOpenSnack(true);
      setSnackMessage("Please Proceed With QR or Other Options.");  
      setSnackStatus("warning") ;
      return;
    }

    setProgress2(true);

    const userId = Cookies.get("loginNo");

    const api = env.REACT_APP_UAPI_URL;
    const apiUrl = `${api}/api/order`;
    const method = "POST";
    const bodyData = { "user_id":userId?.toString() , "order_id":lowBalanceData.order_id.toString(), "is_upi": selectedValue === "c" ? 1 : 0, "is_wallet":proceedCoin, "wallet_balance":lowBalanceData?.wallet_amount,  };

    // const bodyData = { "user_id":Cookies.get("voucherDemoUserId"), "amount":denomination }
    
    const json = await HandleApi({"apiUrl":apiUrl , "method":method, "bodyData":bodyData, "authAccess":authAccess });

    setProgress2(false);

    if(json){
        if(json.status === "success" && json.statuscode === 200 ){

            setOpenSnack(true);
            setSnackMessage(json.message);
            
            setSnackStatus("success");

              setLowBalance(false);
                handleSDialogue();
                handleFormClose()
            setTimeout(() => {
                setLowBalance(false);
                setOpenQr(false)
                setIsActive(false);
                setOpen(false);
            },2000)
            return;
        }

        if(json.status === "error"){
          setOpenSnack(true);
          setSnackMessage(json.message);  
          setSnackStatus("error")   
          setOpenQr(false)

          // setTimeout(() => {
            setLowBalance(false);
            setIsActive(false);
            setOpen(false);
            setSelectedValue("");  
            return; 
        }

        if(json.status === "pending"){
          setOpenSnack(true);
          setSnackMessage(json.message);   
          setSnackStatus("warning");
          handleFormClose()

          setTimeout(() => {
            setLowBalance(false);
            setOpenQr(false)
            setIsActive(false);
            setOpen(false);
            setSelectedValue("");   
            setSelectedValue("");  
        },2000)

        }
    }
  }

  const jaiGateway = async() => {

    setProgress(true)

    const usrId = Cookies.get("voucherDemoUserId");
    const api = env.REACT_APP_UAPI_URL;
    const apiUrl = `${api}/api/b2c_gateway`;
    const method = "POST";
    const bodyData = { "user_id":usrId, "amount": lowBalanceData?.topup_amount ? lowBalanceData?.topup_amount : denomination, "order_id":lowBalanceData.order_id.toString() }
    // const bodyData = { "user_id":Cookies.get("voucherDemoUserId"), "amount":denomination }
    
    const json = await HandleApi({"apiUrl":apiUrl , "method":method, "bodyData":bodyData , "authAccess":authAccess });

    setProgress(false)

    if(json){
        if(json.status === "success" && json.statuscode === 200 ){
            setPgUrl(json.data?.url);
            setHandleSuccess(true);
            setJsonSuccess(json.message);
            setLowBalance(false);
            setOpenUrlDialogue(true)
        }

        if(json.status === "error"){
            setHandleError(true);
            setJsonError(json.message);
        }
    }
  }


  const   handleQr = async( data ) => {

    // 0 == qr and 1 == qr and wallet
    const getQr = sessionStorage.getItem("voucherDemoQr");
    setQrCode(getQr);
    const userNumber =  Cookies.get("userPhone");
    setQrName(userNumber);

    const userName =  Cookies.get("userName");
    setQrName(userName);

    if(data === 0){

      setOpenQr(true);
      setIsActive(true);
      // handleWalletBalance();
    }
    else if(data === 1){
      setOpenQr(true);
      setIsActive(true);
   }
   }

   const handleQrBalance = async() => {

    // For making order via qr code. 

    console.log("make order placement via QR only")

    const url = process.env.REACT_APP_UAPI_URL;
    const apiUrl = `${url}/api/b2cUserWallet`;
    const method = "GET";
    const authToken = Cookies.get("voucherDemoAuth");

    const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess });

    if(json){
      if(json.status === "success" && json.statuscode === 200 ){
          const currentWallet = json.data?.walletbalance;
            // window.alert(` ${parseFloat(currentWallet)} >= ${ parseFloat(lowBalanceData?.order_amount) + parseFloat(lowBalanceData?.wallet_amount)} =  ${parseFloat(currentWallet) >= parseFloat(lowBalanceData?.wallet_amount) + parseFloat(lowBalanceData?.order_amount) }`);
            // window.alert(parseFloat(currentWallet) >= parseFloat(lowBalanceData?.wallet_amount) + parseFloat(lowBalanceData?.topup_amount) + parseFloat(lowBalanceData?.order_amount) )
          if( parseFloat(currentWallet) >= parseFloat(lowBalanceData?.wallet_amount) + parseFloat(lowBalanceData?.order_amount)  ){
              placeQrOrder(json.data?.walletbalance);
          }else{
            return;
          }}        

      if(json.status === "error"){
          setHandleError(true);
          setJsonError(json.message);
          setLowBalance(false);
          setOpenQr(false)
          setIsActive(false);
          setOpen(false);
      }
  }}


//    const handleWalletBalance = async() => {

//     const url = process.env.REACT_APP_UAPI_URL;
//     const apiUrl = `${url}/api/b2cUserWallet`;
//     const method = "GET";
//     const authToken = Cookies.get("voucherDemoAuth");

    
//     const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess });

//     if(json){
//         if(json.status === "success" && json.statuscode === 200 ){
//             const currentWallet = json.data?.walletbalance;
//               // window.alert(openText)
              
//               // window.alert(` ${parseFloat(currentWallet)} >= ${ parseFloat(lowBalanceData?.order_amount) + parseFloat(lowBalanceData?.wallet_amount)} =  ${parseFloat(currentWallet) >= parseFloat(lowBalanceData?.wallet_amount) + parseFloat(lowBalanceData?.order_amount) }`);
//               // window.alert(parseFloat(currentWallet) >= parseFloat(lowBalanceData?.wallet_amount) + parseFloat(lowBalanceData?.topup_amount) + parseFloat(lowBalanceData?.order_amount) )
//             if( parseFloat(currentWallet) >= parseFloat(lowBalanceData?.wallet_amount) + parseFloat(lowBalanceData?.topup_amount)  ){
//               // window.alert("function called")
//                         placeQrOrder(json.data?.walletbalance);
//             }else{
//               return;
//             }      
//             }        

//         if(json.status === "error"){
//             setHandleError(true);
//             setJsonError(json.message);

//             setLowBalance(false);
//             setOpenQr(false)
//             setIsActive(false);
//             setOpen(false);
//         }
//     }
// }

// console.log("selected value : ",selectedValue)

const   placeQrOrder = async(newBal) => {


  try{
    
 setOpenQr(false)
 setIsActive(false);
 setOpen(false);
 
 setProgress3(true);
 setProgress2(true);
 
 // window.alert( ` ${newBal - lowBalanceData?.order_amount >= lowBalanceData?.wallet_amount}, ${lowBalanceData?.wallet_amount} `);  

const userId = Cookies.get("loginNo");

const api = env.REACT_APP_UAPI_URL;
const apiUrl = `${api}/api/order`;
const method = "POST";
const bodyData = { "user_id":userId?.toString() , "order_id":lowBalanceData.order_id?.toString(), "is_upi": selectedValue === "c" ? 1 : 0, "is_wallet":proceedCoin, "wallet_balance":lowBalanceData?.wallet_amount };

// const bodyData = { "user_id":Cookies.get("voucherDemoUserId"), "amount":denomination }

const json = await HandleApi({"apiUrl":apiUrl , "method":method, "bodyData":bodyData, "authAccess":authAccess, "user_id":userId });

setProgress3(false);
setProgress2(false);

if(json){
  if(json.status === "success" && json.statuscode === 200 ){
    setOpenSnack(true);
    setSnackMessage(json.message);
    setSnackStatus("success");
    handleFormClose()

      setLowBalance(false);
      handleSDialogue();
      setTimeout(() => {
          setLowBalance(false);
          setOpenQr(false);
          setIsActive(false);
          setOpen(false);
          window.location.reload();
      },5000)
  }

  if(json.status === "error"){
             setOpenSnack(true);
            setSnackMessage(json.message);
            setSnackStatus("error");

            setTimeout(() => {
              setLowBalance(false);
              setOpenQr(false)
              setIsActive(false);
              setOpen(false);
              setSelectedValue("");   
          },2000)
     
  }

  if(json.status === "pending"){
    setOpenSnack(true);
    setSnackMessage(json.message);
    setSnackStatus("warning");
    handleFormClose();

    setTimeout(() => {
      setLowBalance(false);
      setOpenQr(false)
      setIsActive(false);
      setOpen(false);
      setSelectedValue("");   
  },2000)
  }
}   
  }catch(err){

  }
}

   
   React.useEffect(() => {
    let apiInterval = null;
    if (openQr && isActive && selectedValue === 'c' && proceedCoin === 0 ) {
        // Initial API call
        handleQrBalance();
        // Set up an interval to call the API every 7 seconds
        apiInterval = setInterval(() => {
            if (seconds > 0) {
                handleQrBalance();
            }
        }, 7000);
    }

    else if(openQr && isActive && selectedValue === 'c' && proceedCoin === 1 ){
      // Initial API call
      handleProceed();
      // Set up an interval to call the API every 7 seconds
      apiInterval = setInterval(() => {
          if (seconds > 0) {
            handleProceed();
          }
      }, 7000);
  }
    // Cleanup the interval when the component unmounts or when dependencies change
    return () => {
        if (apiInterval) {
            clearInterval(apiInterval);
        }
    };
}, [openQr, isActive , selectedValue, proceedCoin ]);


  
const handleProceed = async() => {
  const getQr = sessionStorage.getItem("voucherDemoQr");
  setQrCode(getQr);

   console.log("Place order via QR as well as pending coins");
         
   const url = process.env.REACT_APP_UAPI_URL;
   const apiUrl = `${url}/api/b2cUserWallet`;
   const method = "GET";
   
   const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess });

   if(json){
       if(json.status === 'success' && json.statuscode === 200 ){
          handleLowBalance();
           if( parseFloat(json.data?.walletbalance) >= parseFloat(lowBalanceData?.wallet_amount)  + parseFloat(lowBalanceData?.topup_amount)   ){
             handleWalletRequest();
             // window.alert(`current wallet : ${typeof(json.data.walletbalance)} ${json.data.walletbalance} and order amount ${typeof(lowBalanceData?.order_amount)} ${lowBalanceData?.order_amount}`);       
             return; 
           }else{
             return;
           }
       }
   }   
else{
   window.alert("Re-Initiate order.")
   setLowBalance(false);
   setSelectedValue("");
}
}

const handleOpenQr = async() => {
  setOpenQr(false);
  setIsActive(false);
  setSelectedValue("");
  setProceedCoin(0);
  setSeconds(90)
  handleSuccessClose();
  // setTimeout(() => {
  //     window.location.reload();
  // },200)
  // }, [openQr, isActive , selectedValue, proceedCoin ]);
}




const handleFormClose = () => {
  setFormData({});
  closeDialogue();
  setFormAmount("");
  setChecked(false);

  setFormData({
    permAddress1:"",
    permAddress2:"",
    permAddress3:"",
    permCity:"",
    permState:"",
    permPincode:"",
    amount:"",
  })
}

const handlePanNo = (e) => {
  const {name,value} = e.target;
  const pavValue = value?.toUpperCase();
    
  if (pavValue === "") {
    // Clear the input when the value is empty
    setFormData({
      ...formData,
      [name]:""
    })
    return;
  }

  const regexp = /^([a-zA-Z]([a-zA-Z]([a-zA-Z]([P]([a-zA-Z]([0-9]([0-9]([0-9]([0-9]([a-zA-Z])?)?)?)?)?)?)?)?)?)?$/;

  if (regexp.test(pavValue)) {
    // setClientPan(value);
    setFormData({
      ...formData,
      [name]:pavValue
    })
  } else {
    console.log("Invalid input");
    return
  }
}


  return (
    <React.Fragment>
      <Dialog
        open={openData}
        scroll='body'
        onClose={handleFormClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth:{lg:"800px", md:"800px", sm:"630px" },  // Set your width here
                maxHeight:'100vh',
                overflow:'auto'
              },
            },
          }}
      >
        {/* <DialogTitle>{data?.category_name || "Card Details"}</DialogTitle> */}
        <DialogContent sx={{ display:"flex", alignItems:'center', justifyContent:"center", flexDirection:'column'  }} >
          <CloseIcon onClick={() => handleFormClose() } sx={{ fontSize:{lg:'1.4rem', md:"1.4rem", sm:"1.4rem", xs:"1.2rem", position:'absolute', top:0, left:0 } }} />

          <form onSubmit={handleFormSubmit} >

            <Box sx={{ display:"flex", gap:"2rem", width:"100%", flexDirection:{lg:'row', md:"row", sm:'column', xs:"column" }, alignItems:'center' }}>
                
                <Box component="img" src={data?.card_url} sx={{ width:{ lg:"18rem", md:"18rem", sm:"18rem", xs:"15rem" } , height:{ lg:"11rem", md:"11rem", sm:"11rem", xs:"9.2rem" }, borderRadius:2 }} />
                
                <Box sx={{ width:"100%"  }} >
                  <Box sx={{ display:'flex', alignItems:"center", justifyContent:"center" }} >
               <Typography sx={{ fontFamily:"poppins", fontWeight:400, fontSize:{ lg:"0.9rem", md:"0.9rem", sm:"0.9rem", xs:"0.7rem" }, color:"#283f9c", textAlign:'center' }} > {data?.sub_category_name}</Typography>
                <Tooltip title="Edit Details" sx={{ cursor:"pointer" }} >
                <EditIcon onClick={() => handleEdit()} sx={{ position:'absolute', right:10, cursor:"pointer", color:"#808080", fontSize:{lg:"1.5rem", md:"1.5rem", sm:"1.5rem", xs:"1.2rem" } }} />
                </Tooltip>
                  </Box>
                <Divider sx={{ mt:1.5, mb:1 }} />
                
                <Box sx={{ mt:1.5,display:'flex', flexDirection:'column', gap:"0.8rem" }} >

                    <Box sx={{ display:'flex', alignItems:"center", justifyContent:{lg:'space-between', md:"space-between", sm:"space-evenly", xs:"space-evenly" }, flexDirection:{lg:'row', md:"row", sm:"row", xs:"column" }, gap:1.2 }} >

                    <Box sx={{ display:"flex", justifyContent:"center", flexDirection:'column' }} > 
                        <label style={{ fontSize:"0.7rem", color:"#737373" }} > Name*</label>
                        <TextField required InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem", color:"#3b3b3b" } }} value={clientData?.name} name="name" size='small' sx={{ width:{lg:"12rem", sm:"12rem", md:"12rem", xs:"14rem"}, fontSize:"0.8rem", backgroundColor:"#ededed", cursor:'default', userSelect:"none", pointerEvents:'none' }}  />
                    </Box>

                    <Box sx={{ display:"flex", justifyContent:"center", flexDirection:'column' }} > 
                        <label style={{ fontSize:"0.7rem", color:"#737373" }} >E-Mail*</label>
                        <TextField required InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem", color:"#3b3b3b" } }} value={clientData?.email} name="email" size='small' sx={{ width:{lg:"12rem", sm:"12rem", md:"12rem", xs:"14rem"} , fontSize:"0.8rem", backgroundColor:"#ededed", cursor:'default', userSelect:"none", pointerEvents:'none' }}  />
                    </Box>

                    </Box>

                    <Box sx={{ display:'flex', alignItems:"center", justifyContent:{lg:'space-between', md:"space-between", sm:"space-evenly", xs:"space-evenly" }, flexDirection:{lg:'row', md:"row", sm:"row", xs:"column" }, gap:1.2  }} >

                    <Box sx={{ display:"flex", justifyContent:"center", flexDirection:'column' }} > 
                        <label style={{ fontSize:"0.7rem", color:"#737373" }} >Phone Number*</label>
                        <TextField required InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem", color:"#3b3b3b" } }} value={clientData?.phone_no} name="phone" size='small' sx={{ width:{lg:"12rem", sm:"12rem", md:"12rem", xs:"14rem"} , fontSize:"0.8rem", backgroundColor:"#ededed", cursor:'default', userSelect:"none", pointerEvents:'none' }}  />
                    </Box>

                    <Box sx={{ display:"flex", justifyContent:"center", flexDirection:'column' }} > 
                        <label style={{ fontSize:"0.7rem", color:"#737373", marginLeft:"10px" }} >Date Of Birth*</label>

                        <LocalizationProvider sx={{  width:"100%" }} dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                            <DatePicker disabled required disableFuture={true} defaultValue={ dayjs(clientData?.dob)} name="dob" format="DD-MM-YYYY" sx={{
                          width:{lg:"12rem", sm:"12rem", md:"12rem", xs:"14rem"}, // Set overall width
                          '& .MuiInputBase-root': {
                            fontFamily: 'montserrat',
                            fontWeight: 500,
                            fontSize: '0.8rem', // Font size for input text
                            height: '2.4rem', // Control height of input box
                            padding: '0 0.5rem', // Control padding to prevent scroll
                            boxSizing: 'border-box', // Ensure padding does not add to height
                            overflow: 'hidden', // Prevent overflow causing scrollbar
                            backgroundColor:"#ededed",
                            // width:"12rem",
                            marginLeft:{lg:1 , md:1, sm:1, xs:0 },
                            cursor:'default', userSelect:"none", pointerEvents:'none'
                          },
                          '& .MuiFormLabel-root': {
                            fontFamily: 'montserrat',
                            fontWeight: 500,
                            fontSize: '0.75rem', // Font size for label text
                          },
                          '& .MuiInputBase-input': {
                            fontFamily: 'montserrat',
                            fontWeight: 500,
                            fontSize: '0.8rem', // Font size for input field
                            // padding: '0.2rem 0.5rem', // Smaller padding to prevent scroll
                            lineHeight: '0.5', // Line height to ensure the text fits well
                            overflow: 'hidden', // Ensure no scroll within the input
                          },
                          '& .MuiDatePicker-root': {
                            '& .MuiPaper-root': {
                              // Control dropdown size (the calendar popup)
                              maxHeight: '200px', // You can adjust this value
                              overflow: 'auto', // Ensure no overflow inside the dropdown
                            },
                          },
                        }}  />
                            </DemoContainer>
                            </LocalizationProvider>
                    </Box>
                    </Box>
                </Box>     
                <Box>
                </Box>
                </Box>
            </Box>



            <Divider sx={{ width:"100%", mt:2 }} />

            <Box sx={{ display:'flex', alignItems:'center', justifyContent:"space-evenly", width:"100%", mt:2, flexDirection:{lg:'row', md:"row", sm:"row", xs:"column" }, gap:1.2 }} >
          
            <Box sx={{ display:"flex", justifyContent:"center", flexDirection:'column' }} > 
                        <label style={{ fontSize:"0.7rem", color:"#737373" }} >Father Name*</label>
                        <TextField required value={clientData?.father_name === null || clientData?.father_name === "" ? formData?.fatherName : clientData?.father_name } InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem", color:"#3b3b3b" } }} name="fatherName" onChange={ clientData?.father_name === null || clientData?.father_name === "" ? handleInputChange : clientData?.father_name  } size='small' sx={{ width:{lg:"12rem", md:"12rem", sm:"10rem" }, fontSize:"0.8rem", backgroundColor:  clientData?.father_name === null || clientData?.father_name === "" ? 'white' : "#ededed" }} />
                    </Box>


                <Box sx={{ display:"flex", justifyContent:"center", flexDirection:'column' }} > 
                        <label style={{ fontSize:"0.7rem", color:"#737373" }} >Gender*</label>
                        <FormControl>
                        <Select required defaultValue="Male" value={formData.gender} name="gender" onChange={handleInputChange} size="small" sx={{  width:{lg:"12rem", md:"12rem", sm:"10rem", xs:"13rem" } , fontFamily:"montserrat", fontWeight:500 }} >
                            <MenuItem sx={{ fontFamily:"montserrat", fontWeight:500 }} value="MALE" >Male</MenuItem>
                            <MenuItem  sx={{ fontFamily:"montserrat", fontWeight:500 }} value="FEMALE" >Female</MenuItem>
                            <MenuItem sx={{ fontFamily:"montserrat", fontWeight:500 }} value="OTHER" >Other</MenuItem>
                        </Select>
                        </FormControl>
                    </Box>


                    <Box sx={{ display:"flex", justifyContent:"center", flexDirection:'column' }} > 
                        <label  style={{ fontSize:"0.7rem", color:"#737373" }} >PAN Card Number</label>
                        <TextField name="pan" required InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem", color:"#3b3b3b" } }} value={clientData?.pan_no === null || clientData?.pan_no === "" ? formData?.pan : clientData?.pan_no  } onChange={ clientData?.pan_no === null || clientData?.pan_no === "" ?  handlePanNo : null } size='small' sx={{ width:{lg:"12rem", md:"12rem", sm:"10rem" }, fontSize:"0.8rem", backgroundColor: clientData?.pan_no === null || clientData?.pan_no === "" ? "white" : "#ededed"  , cursor: clientData?.pan_no === null ||  clientData?.pan_no === "" ? null : 'default' , userSelect: clientData?.pan_no === null ||  clientData?.pan_no === "" ? null : 'none' , pointerEvents: clientData?.pan_no === null ||  clientData?.pan_no === "" ? null : 'none' }}  />
                    </Box>
                </Box>


                <Divider sx={{ width:"100%", mt:2, fontSize:"0.75rem", color:'#283f9c' }} >Enter Communication Address</Divider>

                <Box sx={{ display:'flex', alignItems:'center', justifyContent:"space-evenly", width:"100%", mt:2, flexDirection:{lg:'row', md:"row", sm:"row", xs:"column" }, gap:1.2 }} >

                <Box sx={{ display:"flex", justifyContent:"center", flexDirection:'column' }} > 
                        <label style={{ fontSize:"0.7rem", color:"#737373" }} >Address 1*</label>
                        <TextField required InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem", color:"#3b3b3b" } }} value={clientData?.address1} name="commAddress" size='small' sx={{ width:{lg:"12rem", md:"12rem", sm:"10rem" }, fontSize:"0.8rem",  backgroundColor:"#ededed", cursor:'default', userSelect:"none", pointerEvents:'none'  }}  />
                    </Box>
                    
                    <Box sx={{ display:"flex", justifyContent:"center", flexDirection:'column' }} > 
                        <label style={{ fontSize:"0.7rem", color:"#737373" }} >Address 2</label>
                        <TextField InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem", color:"#3b3b3b" } }} value={clientData?.address2} name="commAddress2" size='small' sx={{ width:{lg:"12rem", md:"12rem", sm:"10rem" }, fontSize:"0.8rem",  backgroundColor:"#ededed", cursor:'default', userSelect:"none", pointerEvents:'none'  }}  />
                    </Box>

                    <Box sx={{ display:"flex", justifyContent:"center", flexDirection:'column' }} > 
                        <label style={{ fontSize:"0.7rem", color:"#737373" }} >Address 3</label>
                        <TextField InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem", color:"#3b3b3b" } }} value={formData?.commAddress3} name="commAddress3" size='small' sx={{ width:{lg:"12rem", md:"12rem", sm:"10rem" }, fontSize:"0.8rem",  backgroundColor:"#ededed" , cursor:'default', userSelect:"none", pointerEvents:'none' }}  />
                    </Box>

                </Box>

                
                <Box sx={{ display:'flex', alignItems:'center', justifyContent:"space-evenly", width:"100%", mt:1, flexDirection:{lg:'row', md:"row", sm:"row", xs:"column" }, gap:1.2 }} >

                <Box sx={{ display:"flex", justifyContent:"center", flexDirection:'column' }} > 
                        <label style={{ fontSize:"0.7rem", color:"#737373" }} >City*</label>
                        <TextField required InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem", color:"#3b3b3b" } }} value={clientData?.city}  size='small' sx={{ width:{lg:"12rem", md:"12rem", sm:"10rem" }, fontSize:"0.8rem", backgroundColor:"#ededed", cursor:'default', userSelect:"none", pointerEvents:'none'  }} />
                    </Box>
                    
                    <Box sx={{ display:"flex", justifyContent:"center", flexDirection:'column' }} > 
                        <label style={{ fontSize:"0.7rem", color:"#737373" }} >State*</label>
                        <TextField required InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem", color:"#3b3b3b" } }} value={clientData?.state}  size='small' sx={{ width:{lg:"12rem", md:"12rem", sm:"10rem" }, fontSize:"0.8rem", backgroundColor:"#ededed" , cursor:'default', userSelect:"none", pointerEvents:'none' }}  />
                    </Box>

                    <Box sx={{ display:"flex", justifyContent:"center", flexDirection:'column' }} > 
                        <label style={{ fontSize:"0.7rem", color:"#737373" }} >Pincode*</label>
                        <TextField required InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem", color:"#3b3b3b" } }} value={clientData?.pincode}  size='small' sx={{ width:{lg:"12rem", md:"12rem", sm:"10rem" }, fontSize:"0.8rem", backgroundColor:"#ededed" , cursor:'default', userSelect:"none", pointerEvents:'none' }}  />
                    </Box>

                </Box>

                <Divider sx={{ width:"100%", mt:2, fontSize:"0.75rem", color:'#283f9c' }} >Enter Permanent Address</Divider>


                <Box sx={{ display:'flex', alignItems:'center', justifyContent:"space-evenly", width:"100%", mt:1, flexDirection:{lg:'row', md:"row", sm:"row", xs:"column" }, gap:1.2 }} >

                <Box sx={{ display:"flex", justifyContent:"center", flexDirection:'column' }} > 
                        <label style={{ fontSize:"0.7rem", color:"#737373" }} >Address 1</label>
                        <TextField name="permAddress1" required InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem", color:"#3b3b3b" } }} value={ checked ? clientData.address1 : formData.permAddress1} onChange={checked ? "" : handleInputChange} size='small' sx={{ width:{lg:"12rem", md:"12rem", sm:"10rem" }, fontSize:"0.8rem", cursor: checked ? 'default' : null, userSelect: checked ? "none" : null, pointerEvents: checked ? 'none' : null }}  />
                    </Box>
                    
                    <Box sx={{ display:"flex", justifyContent:"center", flexDirection:'column' }} > 
                        <label style={{ fontSize:"0.7rem", color:"#737373" }} >Address 2</label>
                        <TextField name="permAddress2" InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem", color:"#3b3b3b" } }} value={ checked ? clientData.address2 : formData.permAddress2 } onChange={checked ? "" : handleInputChange}  size='small' sx={{ width:{lg:"12rem", md:"12rem", sm:"10rem" }, fontSize:"0.8rem", cursor: checked ? 'default' : null, userSelect: checked ? "none" : null, pointerEvents: checked ? 'none' : null }}  />
                    </Box>

                    <Box sx={{ display:"flex", justifyContent:"center", flexDirection:'column' }} > 
                        <label style={{ fontSize:"0.7rem", color:"#737373" }} >Address 3</label>
                        <TextField name="permAddress3" InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem", color:"#3b3b3b" } }} value={ checked ? clientData.address3 : formData.permAddress3} onChange={checked ? "" : handleInputChange}  size='small' sx={{ width:{lg:"12rem", md:"12rem", sm:"10rem" }, fontSize:"0.8rem",cursor: checked ? 'default' : null, userSelect: checked ? "none" : null, pointerEvents: checked ? 'none' : null }}  />
                    </Box>

                </Box>

                
                
                <Box sx={{ display:'flex', alignItems:'center', justifyContent:"space-evenly", width:"100%", mt:1, flexDirection:{lg:'row', md:"row", sm:"row", xs:"column" }, gap:1.2 }} >

                <Box sx={{ display:"flex", justifyContent:"center", flexDirection:'column' }} > 
                        <label style={{ fontSize:"0.7rem", color:"#737373" }} >City</label>
                        <TextField name="permCity" required InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem", color:"#3b3b3b" } }} value={ checked ? clientData.city: formData.permCity} onChange={checked ? "" : handleInputChange}  size='small' sx={{ width:{lg:"12rem", md:"12rem", sm:"10rem" }, fontSize:"0.8rem",cursor: checked ? 'default' : null, userSelect: checked ? "none" : null, pointerEvents: checked ? 'none' : null }}  />
                    </Box>
                    
                    <Box sx={{ display:"flex", justifyContent:"center", flexDirection:'column' }} > 
                        <label style={{ fontSize:"0.7rem", color:"#737373" }} >State</label>
                        <TextField name="permState" required InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem", color:"#3b3b3b" } }} value={ checked ? clientData.state : formData.permState} onChange={checked ? "" : handleInputChange} size='small' sx={{ width:{lg:"12rem", md:"12rem", sm:"10rem" }, fontSize:"0.8rem",cursor: checked ? 'default' : null, userSelect: checked ? "none" : null, pointerEvents: checked ? 'none' : null }}  />
                    </Box>

                    <Box sx={{ display:"flex", justifyContent:"center", flexDirection:'column' }} > 
                        <label style={{ fontSize:"0.7rem", color:"#737373" }} >Pincode</label>
                        <TextField name="permPincode" required InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem", color:"#3b3b3b" } }} value={ checked ? clientData.pincode : formData.permPincode} onChange={checked ? "" : handleInputChange} size='small' sx={{ width:{lg:"12rem", md:"12rem", sm:"10rem" }, fontSize:"0.8rem", cursor: checked ? 'default' : null, userSelect: checked ? "none" : null, pointerEvents: checked ? 'none' : null }}  />
                    </Box>

                </Box> 


                <Box sx={{ display:'flex', alignItems:'center', width:"100%", marginLeft:"auto" }} >
                    <Checkbox
                    checked={checked}
                    onChange={handleCheckChange}
                    inputProps={{ 'aria-label': 'controlled' }}              
                   />
                    <Typography sx={{ fontFamily:'poppins', fontWeight:400 , fontSize:{lg:"0.8rem" , sm:"0.8rem", md:"0.8rem", xs:"0.7rem" }, color:'black' }} > Same As Communication Address</Typography>
                    </Box>
                    
                <Divider sx={{ mt:2, width:"100%" }} />

                <Box sx={{ display:"flex", justifyContent:'space-evenly', mt:2, width:"100%", flexDirection:{lg:'row', md:"row", sm:"row", xs:"column" } }}>
                  
                  <Box sx={{ display:'flex' , justifyContent:'center', flexDirection:'column' }} >
                    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }} >
                    <label style={{ fontSize:"0.65rem", color:"#737373" }} > {data?.card_charges === 0 ? "Enter Load Amount" : "Card Charges"}</label>
                  {
                    data?.card_charges === 0 ? 
                    <label style={{ fontSize:"0.6rem", color:"#737373" }} >Max Amount - 10,000</label>: null
                  }
                    </Box>
                  <TextField required size='small' value={  data?.card_charges === 0 ? formAmount : data?.card_charges } onChange={(e)=>handleAmount(e.target.value)} sx={{ width:{lg:"15rem", md:"15rem", sm:"15rem" } , fontSize:"0.8rem", backgroundColor:data?.card_charges === 0 ? null :"#ededed", cursor: data?.card_charges !== 0 ? 'default' : null, userSelect: data?.card_charges !== 0 ? "none" : null, pointerEvents: data?.card_charges !== 0 ? 'none' : null }}  />
                  </Box>

                  

                <Button variant='contained' type='submit' sx={{ fontFamily:"montserrat", fontWeight:500, mt:2, width:{lg:"12rem", md:"12rem", sm:"10rem" } }} > Submit Details </Button>

                </Box>

                </form>

        </DialogContent>
      </Dialog>

      <ProfileSection openProfile={openProfile} handleProfileClose={handleProfileClose} Transition={Transition} name={name}
      eezibWhite={eezibWhite} handleClose={handleClose}
      
    />

    <SnackBarSuccess open={openSnack} status={snackStatus} message={snackMessage} snackClose={() => setOpenSnack(false) } />

    <Dialog
      open={lowbalance}
      aria-describedby="alert-dialog-slide-description"
      sx={{borderRadius:4 ,
          "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth:{lg:"900px", md:"900px ", sm:"900px", xs:"400px"},  // Set your width here
              },
            },
      
        }}
      >
        <CloseIcon onClick={() => handleLowBalance() } sx={{ position:"absolute", right:0, color:'#8c8c8c', cursor:'pointer' }} />   

          <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-around', flexDirection:{ xs:"column", sm:"row", md:"row", lg:"row"  } }} >
              <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }} >
              {/* <Typography sx={{ fontFamily:"montserrat", fontWeight:500, mt:1 }} > Order Summary </Typography> */}
              <Box component="img" src={eezibLogo} sx={{ width:{sm:"5rem", md:"5rem", lg:"7rem" , xs:"7rem"}, mt:1 }} />  
                  <Box component="img" src={pgImage} sx={{ width:{sm:"20rem", md:"25rem", lg:"25rem" , xs:"15rem"} }} />  
              </Box>
              <Box sx={{ }} >
                  <Box sx={{ display:'flex',alignItems:'center', justifyContent:"center", flexDirection:'row'  }}  >

                  <Box sx={{ display:'flex',alignItems:'left', flexDirection:'column' }} >
                      <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:{lg:"1rem", md:"1rem", sm:"0.9rem",xs:"0.8rem" } }} > Eezib Order Value &nbsp;: <span style={{ color:"#b3b3b3" }} ></span>  <span style={{ }} >  </span> </Typography>
                      <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:{lg:"1rem", md:"1rem", sm:"0.9rem",xs:"0.8rem" } }} > Eezib Wallet Value : <span style={{ color:"#b3b3b3" }} ></span>  <span style={{ }} >  </span> </Typography>
                      <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:{lg:"1rem", md:"1rem", sm:"0.9rem",xs:"0.8rem" } }} > Eezib Topup Value : <span style={{ color:"#b3b3b3" }} ></span>  <span style={{ }} >  </span> </Typography>
                  </Box>

                  <Box sx={{ display:'flex',alignItems:'left', flexDirection:'column', p:1 }} >
                      <Box component="img" src={eeLogo} sx={{ width:{lg:"1.6rem", md:"1.6rem", sm:"1.6rem", xs:"1rem" }, mt:0.5, mb:0.5 }} />
                      <Box component="img" src={eeLogo} sx={{ width:{lg:"1.6rem", md:"1.6rem", sm:"1.6rem", xs:"1rem" }, mt:0.5, mb:0.5 }} />
                      <Box component="img" src={eeLogo} sx={{ width:{lg:"1.6rem", md:"1.6rem", sm:"1.6rem", xs:"1rem" }, mt:0.5, mb:0.5 }} />    
                  </Box>

                  <Box sx={{ display:'flex',alignItems:'left', flexDirection:'column', p:1 }} >
                  <Typography sx={{ color:'#d62b2e', fontFamily:'montserrat', textAlign:"right" ,fontWeight:500, fontSize:{lg:"1rem", md:"1rem", sm:"0.9rem",xs:"0.8rem" }  }} >
                    {lowBalanceData?.order_amount}
                    </Typography>
                  <Typography sx={{ color:'#d62b2e', fontFamily:'montserrat', textAlign:"right" ,fontWeight:500 , fontSize:{lg:"1rem", md:"1rem", sm:"0.9rem",xs:"0.8rem" } }} >
                    {lowBalanceData?.wallet_amount} 
                    </Typography>
                  { lowBalanceData?.topup_amount === null || 0 ? null : 
                    <Typography sx={{ color:'#d62b2e', fontFamily:'montserrat', textAlign:"right" ,fontWeight:500 , fontSize:{lg:"1rem", md:"1rem", sm:"0.9rem",xs:"0.8rem" } }} > 
                      {lowBalanceData?.topup_amount} 
                      </Typography>
                  }
                  </Box>

                      </Box>
                          <Divider fullWidth sx={{ marginTop:"1rem", marginBottom:"1rem" }} />
                      <Box>



                          <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:2, width:"15rem" }} >
                              {/* <Typography sx={{ fontFamily:'montserrat', fontWeight:400, color:"#2a9adb", textTransform:'capitalize', fontSize:"0.8rem", mt:1 }} >Add Money to pay </Typography> */}
                              <Box sx={{ mt:2, border:'1px solid #ababab', width:{lg:"20rem", md:"20rem", sm:"17rem", xs:"15rem"   } , borderRadius:2 }} >

                                  <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', gap:2, padding:"0px 2px" }} >

                              <Checkbox
                                  // checked={selectedValue === 'a'}
                                  onChange={handleCheck}
                                  value="a"
                                  name="radio-buttons"

                              />

                              <Typography sx={{ fontFamily:'montserrat', fontWeight:500, textTransform:"capitalize" , color:lowBalanceData?.topup_amount !== 0 ? "gray" : "black" , fontSize:{lg:"1rem", md:"1rem", sm:"0.9rem", xs:"0.7rem" } }} >Proceed With Coins</Typography>

                              <Box component='img' src={eeLogo} sx={{ width:{lg:"2rem", md:"2rem", sm:"2rem", xs:"1.5rem" }, padding:"0px 5px" }} />

                                  </Box>

                              {
                                  lowBalanceData?.topup_amount === 0 ? null : 
                              <Typography sx={{ fontFamily:'montserrat',fontWeight:500, fontSize:{lg:"0.7rem" , md:"0.6rem", sm:"0.7rem", xs:"0.6rem" }, color:"#fc5b5b", textAlign:'center' }} > Topup Required - ee 
                              {lowBalanceData?.topup_amount}
                               </Typography> 
                              }
                              </Box>

                              <Box sx={{ border:"1px solid #ababab",  width:{lg:"20rem", md:"20rem", sm:"17rem", xs:"15rem" } , borderRadius:2 }} >
                                  
                              <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', gap:2, padding:"0px 2px" }} >

                              <Radio
                              checked={selectedValue === 'c'}
                              onChange={handleChange}                              
                              checkedIcon={ <RadioButtonCheckedIcon/> }
                              value="c"
                              name="radio-buttons"
                              inputProps={{ 'aria-label': 'C' }}
                              disabled={ lowBalanceData?.pg_status === 0 }
                              />

                              <Typography sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:{lg:"1rem" , md:"1rem", sm:"0.9rem", xs:"0.8rem" } ,textTransform:"capitalize", color:lowBalanceData?.qr_status === 0 ? "gray" : "black" }} >Scan With QR</Typography>

                              <Box component='img' src={userQr} sx={{ width:{lg:"2rem", md:"2rem", sm:"2rem", xs:"1.5rem" }, padding:"0px 5px" }} />

                                  </Box>

                              </Box>

                              <Box sx={{ border:"1px solid #ababab",  width:{lg:"20rem", md:"20rem", sm:"17rem", xs:"15rem" } , borderRadius:2 }} >               
                              
                              <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', gap:2, padding:"0px 2px" }} >

                              <Radio
                              checked={selectedValue === 'b'}
                              onChange={handleChange}
                              value="b"
                              name="radio-buttons"
                              inputProps={{ 'aria-label': 'B' }}
                              disabled={ lowBalanceData?.pg_status === 0 }
                          />

                              <Typography sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:{lg:"1rem" , md:"1rem", sm:"0.9rem", xs:"0.8rem" } ,textTransform:"capitalize", color:lowBalanceData?.pg_status === 0 ? "gray" : "black" }} >other Options</Typography>

                              <Box component='img' src={cardIcon} sx={{ width:{lg:"2rem", md:"2rem", sm:"2rem", xs:"1.5rem" }, padding:"0px 5px" }} />

                                  </Box>

                              {/* {
                                  progress ?   <CircularProgress/> :
                                  <Button fullWidth00000p disabled={ lowBalanceData?.pg_status === 0 || progress2 } onClick={() => jaiGateway()} endIcon={ <AccountBalanceIcon/> } variant='contained' sx={{ fontFamily:'montserrat', fontWeight:500, mt:1 }} > purchase more coins </Button>
                              } */}
                              </Box>

                                  {
                                      progress || progress2 ? <CircularProgress/> : 
                              <Button disabled={ progress2 } variant='contained' onClick={ () =>  selectedValue === '' && proceedCoin === 1 ? handleWalletRequest() : selectedValue === 'b' ? jaiGateway() : selectedValue === 'c' && proceedCoin === 0 ? handleQr(0) : selectedValue === 'c' && proceedCoin === 1 ? handleQr(1) :  handleExceptErr()  } sx={{ fontFamily:'montserrat', fontWeight:400, width:{lg:"20rem", md:"20rem", sm:"17rem", xs:"15rem" } , mb:{xs:"1rem"} }} > make payment </Button>
                                  }
                          </Box>
                      </Box>
                  </Box>              
          </Box>
    </Dialog>


    <Dialog
        open={openQr}
        // onClose={handleOpenQr}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius:3 }}
      >
        
        <CloseIcon onClick={handleOpenQr} sx={{ cursor:'pointer', position:'absolute', right:0, color:'red', fontSize:"1.2rem",  }} />
        <DialogContent sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }} >
        <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }}  >
                <Box component="img" src={eezibLogo} sx={{ width:"9rem" }} />
                <Typography sx={{ fontFamily:'Poppins', fontWeight:500, fontSize:"0.8rem" }} >QR Accepted Here</Typography>
                <Divider variant='middle' sx={{ width:"100%", mt:1 }} />
          <Typography sx={{ fontFamily:'Poppins', fontWeight:450, color:"#34449B", fontSize:"0.8rem", mt:2 }} >  {sessionStorage.getItem("messageUPI")} </Typography>
       
                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', mt:0 }}  >
                  {
                   parseFloat(lowBalanceData?.topup_amount) > 0.01 && selectedValue == "c" && proceedCoin == 1 ? 
                   <Typography sx={{ fontFamily:'Poppins', fontSize:'0.85rem', color:'#eb5757' }} > Topup Required - ₹ {lowBalanceData?.topup_amount} </Typography> : null
                  }                                       
                    <div style={{ width:"15rem" }} dangerouslySetInnerHTML={{ __html: sessionStorage.getItem("userQr") }} />

                    <LinearProgress sx={{ width:"100%", mt:1 }} />

                    <Typography sx={{ fontFamily:'Poppins', fontWeight:450, color:"#34449B", fontSize:"0.8rem", mt:1 }} >  {sessionStorage.getItem("userEmail")} </Typography>
                    <Typography sx={{ fontFamily:'Poppins', fontWeight:450, color:"#34449B", fontSize:"0.8rem" }} >  {sessionStorage.getItem("userPhone")} </Typography>
                    {/* <Typography sx={{ fontFamily:'Poppins', fontWeight:500, fontSize:"0.8rem", mt:1 }} >Scan & Pay</Typography> */}
                </Box>
                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly', width:"100%" }} >
                    <Box component='img' src="https://cdn.icon-icons.com/icons2/2699/PNG/512/upi_logo_icon_169316.png" sx={{width:"5rem"}} />
                    <Box component="img" src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/bhim-app-icon.png" sx={{ width:"6rem" }} />
                </Box>
                    <Divider variant='middle' sx={{ width:"100%" }} />
                <Box sx={{  fontFamily:"Poppins", fontWeight:400, color:'gray', textTransform:"capitalize", fontSize:"0.7rem", mt:1 }} >
                    Session will be expire in {seconds} seconds
                </Box>

            </Box>
        </DialogContent>
      </Dialog>


      <Dialog      
        open={successDialogue}
        onClose={handleSuccessClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius:3 }}>


        <CloseIcon onClick={handleOpenQr} sx={{ cursor:'pointer', position:'absolute', right:0, color:'red', fontSize:"1.2rem",  }} />
        <DialogContent sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', margin:0, paddding:0 }} >
            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }}  >
            <Box component='img' src={eezibLogo} sx={{ width:'6rem'

             }} />
                <Divider variant='middle' sx={{ width:"100%", mt:1.5 }} />
              <Box component='img' src={orderImage} sx={{ width:{lg:"28rem", xs:"18rem" }, padding:0, margin:0, mt:0, backgroundColor:'black' }} />
              <Typography className='orderText' sx={{ fontFamily:'Poppins', fontWeight:700, fontSize:"2.5rem", textTransform:'capitalize', mt:1  }} >Thank You.</Typography>
              <Typography className='errorText' sx={{ fontFamily:'Poppins', fontWeight:500, color:"" ,fontSize:{lg:"1.1rem", sm:"1rem", md:"1.1rem", xs:"0.7rem" }, textTransform:'capitalize'  }} > Order placed successfully, Happy Shopping.</Typography>      
              {/* <Typography  sx={{ fontFamily:'Poppins', fontWeight:400 ,fontSize:"0.75rem", color:"gray", textTransform:'capitalize'  }} >Thanks for shopping and trusting eezib  </Typography>                 */}
          
            </Box>
        </DialogContent>

      </Dialog>



    </React.Fragment>
  );
}
